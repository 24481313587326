import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";

const VariantsRadioGroup = (props) => {
  const [selected, setSelected] = useState(props.init[0].name);

  const plans = ["Black Print", "Full-colour Print"];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleChange = (value) => {
    setSelected(value);
    props.setVariants(props.name, value);
  };

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">{props.name}</h3>

      <RadioGroup
        value={selected}
        onChange={(value) => handleChange(value)}
        className="mt-2"
      >
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-2">
          {plans.map((data) => (
            <RadioGroup.Option
              key={data}
              value={data}
              className={({ active }) =>
                classNames(
                  "cursor-pointer bg-white shadow-sm",
                  "group relative flex items-center justify-center rounded-md border border-gray-300 py-3 text-sm hover:bg-gray-50 focus:outline-none sm:flex-1 "
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <RadioGroup.Label as="span">{data}</RadioGroup.Label>
                  <span
                    className={classNames(
                      active ? "border-2" : "border-2",
                      checked ? "border-indigo-500" : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-md"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default VariantsRadioGroup;
