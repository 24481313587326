import React, { useState } from "react";

const VariantsTextarea = (props) => {
  const [value, setValue] = useState(props.init[0].name ?? 0);

  const handleChange = (value) => {
    setValue(value);
    props.setVariants(props.name, value);
  };

  return (
    <div id={`v-${props.name.replace(/\s+/g, "-")}`}>
      <h3 className="text-sm font-medium capitalize">{props.name}</h3>
      <textarea
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        max={200}
        rows={4}
        className="mt-1 p-2 block w-full border border-gray-200 rounded-md outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
        placeholder={
          /roll/i.test(props.productName)
            ? "For multiple hip numbers, provide details for each hip number and quantity (eg. #1-4, 3 rolls each)"
            : /individual/i.test(props.productName)
            ? "For multiple individual hip numbers, provide details for each hip number and quantity (eg. #1-2, 50 numbers each, #8, 100 numbers)"
            : "For multiple number ranges and colour requests, provide details here."
        }
      />
    </div>
  );
};

export default VariantsTextarea;
